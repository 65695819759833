<template>
  <div v-loading="loading.init" class="print-page">
    <div class="print-btn">
      <PrintButton
        :type="PRINT_BUTTON.type"
      />
      <div class="margin-24">
        <el-radio-group v-model="form.codeType">
          <el-radio
            v-for="item in CODE_TYPE"
            :key="item.value"
            :label="item.value"
          >
            {{ item.label }}
          </el-radio>
        </el-radio-group>
      </div>
    </div>
    <div v-for="(printList, printNumber) in prints" :key="printNumber">
      <component
        :is="printList.component"
        :print-list="printList"
        :code-type="form.codeType"
      />
    </div>
  </div>
</template>

<script>
import loadingMixin from '@thales/loading';
import moment from '@/utils/moment';
import { YMD } from '@/constant/timeFormat';
import PrintButton from '../components/PrintButton/PrintButton.vue';
import Normal from './normal/index.vue';
import customized1 from './customized1/index.vue';
import customized2 from './customized2/index.vue';

import { PRINT_BUTTON } from '../components/PrintButton/config';
import config from './config';
import { DICTIONARY, CODE_TYPE } from './constant';
import batchCreate from './api';

export default {
  name: 'PrintGoodsLabel',
  components: {
    PrintButton,
    Normal,
    customized1,
    customized2,
  },
  mixins: [loadingMixin],
  data() {
    return {
      PRINT_BUTTON,
      config,
      CODE_TYPE,
      DICTIONARY,
      prints: [],
      form: {
        codeType: DICTIONARY.BAR_CODE,
      },
      loading: {
        init: false,
      },
    };
  },
  created() {
    const { res } = this.$route.query;
    if (res) {
      this.init(JSON.parse(res));
    }
  },
  methods: {
    async init(data) {
      try {
        const infoList = data.tableList.map((item) => ({
          goodsId: item.goodsId,
          receiptOrderDetailId: item.id,
          count: item.count,
        }));
        const resp = await batchCreate({ tagType: data.labelRadio, infoList });
        this.prints = resp.map((item, index) => {
          const findGoods = data.tableList.find((list) => list.goodsId === item.goodsId);
          let printBatchNum = null;
          if (findGoods) {
            if (data.labelRadio === DICTIONARY.BATCH_CODES) {
              printBatchNum = findGoods.printBatchNum[index].value || 1;
            }
            return {
              ...item,
              component: config[data.labelRadio],
              printBatchNum,
              printNum: findGoods.printNum || 1,
              productDate: moment.format(item.productDate, YMD),
            };
          }
          return {};
        });
      } catch (error) {
        this.prints = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.print-page {
  max-width: 1123px;
  margin: 0 auto;
}

.print-btn {
  display: flex;
  align-items: baseline;

  .margin-24 {
    margin-left: 24px;
  }
}

@media print {
  .print-btn {
    display: none;
  }
}
 </style>
