<template>
  <!-- 路径【入库管理】>>>【入库单管理】（详情）>>>打印货品标签 -->
  <div class="printWj">
    <div
      v-for="item in printList.printNum"
      :key="item"
      class="print-item"
    >
      <nh-barcode
        v-if="printList.tagCode && codeType=== DICTIONARY.BAR_CODE"
        :value="printList.tagCode"
        :text="printList.tagCode"
        size="big"
      />
      <div v-else-if="printList.tagCode" class="qrcode">
        <nh-qrcode
          :text="printList.tagCode"
        />
        <p>{{ printList.tagCode }}</p>
      </div>
      <div class="body">
        <p>{{ printList.goodsCode }}</p>
        <p class="weight600">
          {{ `${printList.goodsName} &nbsp; ${printList.goodsSpecifications}` }}
        </p>
        <p>码盘：{{ printList.storageRule }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { DICTIONARY } from '../constant';

export default {
  name: 'PrintGoodsLabelNormal',
  props: {
    codeType: {
      type: String,
      default: '',
    },
    printList: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      DICTIONARY,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
    },
  },
};
</script>

<style lang="scss" scoped>
.printWj {
  display: flex;
  flex-wrap: wrap;
}

.print-item {
  padding: 8px;
  page-break-after: always;

  .qrcode {
    font-size: 12px;
  }

  .body {
    padding-top: 16px;

    p {
      line-height: 24px;
    }

    .weight600 {
      font-weight: 600;
    }
  }
}

@media print {
  .printWj {
    display: block;
  }
}
</style>
